export default function closeSidebar() {
    document.addEventListener("click", function (e) {
        const sidebarIsOpen = sidebar.classList.contains("active")
        if (sidebarIsOpen) {
            const sidebar = document.getElementById("sidebar");
            const isNotSidebar = sidebar !== e.target && !e.target.closest("#sidebar")

            const isNotDataToggle = !e.target.getAttribute("data-toggle") && !e.target.closest("[data-toggle]")

            if (isNotSidebar && isNotDataToggle && sidebarIsOpen) $('.sidebar-offcanvas').removeClass('active')
        }
    })
}
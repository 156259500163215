export default function dataMasks() {
    const inputs = document.querySelectorAll("[data-mask]")

    if (!inputs) return

    inputs.forEach(input => applyMask(input))

    function applyMask(input) {
        const mask = input.dataset.mask
        $(input).mask(mask)
    }
}

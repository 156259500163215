export default function showToast() {
  const url = GLOBAL.url;
  if (url.searchParams.has("toast")) {
    const mensagemToastify = url.searchParams.get("toast");
    Toastify({
      text: mensagemToastify,
      duration: 3000,
      position: "center",
      style: {
        background: "#339AF0",
      },
    }).showToast();

    url.searchParams.set("toast", "");
    url.searchParams.delete("toast");
    window.history.pushState({}, "", url);
  }
}
